import { createClient } from '@supabase/supabase-js'

export const API_URL = 'http://localhost:8180';

export const supabaseUrl = 'https://ckxdejodijysavzvrekb.supabase.co'
export const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImNreGRlam9kaWp5c2F2enZyZWtiIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODMyNjU1NDQsImV4cCI6MTk5ODg0MTU0NH0.BJJRofQFI14ilHeI9LGDHVOXGGOPAsDo4hPomRi4A1Q'
// https://ckxdejodijysavzvrekb.supabase.co


export const supabase = createClient(supabaseUrl, supabaseKey);


export const user_organization_name = "Sarvagna Infotech";

export const user_organization_app_heading = "Psychometric Test";

// let { data: intg_assessments, error } = await supabase
//   .from('intg_assessments')
//   .select('*')
