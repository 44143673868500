import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import Button from '../../components/button/button.component';
import Directory from '../../components/directory/directory.component';
import { selectUserFirstName, selectIsAuthenticated } from '../../store/authentication/authentication.selector';
import { user_organization_name, user_organization_app_heading } from "../../store/config";

const Home = () => {
  const firstName = useSelector(selectUserFirstName);
  const navigate = useNavigate();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  return (
    <div>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
      }}>
        <img style={{
          display: 'block',
        }} src="client_logo.png" />
        <h3>
          {/* Software | Hardware | Online MBA/MCA */}
        </h3>

      </div>
      <h1>
        {
          firstName ? `Welcome ${firstName}` :
            `${user_organization_name}: ${user_organization_app_heading}`
        }
      </h1>

      {isAuthenticated && <h2>Authenticated</h2>}
      {!isAuthenticated && <>
        <hr style={{
          width: '100%',
          marginTop: 20,
          marginBottom: 20,
        }} />
        <Button

          hoverBackgroundColor="#34b800" hoverForegroundColor="white"
          // on click, navigate to signup page
          onClick={() => navigate('/signup')}
        >
          Sign Up (New Users)
        </Button>

        <hr style={{
          width: '0%',
          marginTop: 20,

        }} />
        <Button

          hoverBackgroundColor="#0556b3" hoverForegroundColor="white"
          // on click, navigate to signup page
          onClick={() => navigate('/signin')}
        >
          Sign In (Existing Users)
        </Button>
        <hr style={{
          width: '100%',
          marginTop: 20,
          marginBottom: 20,
        }} />

      </>}



      {/* <h2>Welcome to Psychometric Test</h2> */}
      <Directory />
      <Outlet />
    </div>
  );
};

export default Home;
