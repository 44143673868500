import { SIGNUP_ACTION_TYPES } from "./signup.types";

export const SIGNUP_INITIAL_STATE = {
  // authUser: [],
  isLoading: false,
  error: null,
};

export const signUpReducer = (
  state = SIGNUP_INITIAL_STATE,
  action = {}
) => {
  const { type, payload } = action;

  switch (type) {
      
    case SIGNUP_ACTION_TYPES.AJAX_SIGNUP_SUBMIT_START:
      return { ...state, isLoading: true };
    case SIGNUP_ACTION_TYPES.AJAX_SIGNUP_SUBMIT_SUCCESS:
      // return { ...state, authUser: payload, isLoading: false };
      return { ...state, isLoading: false };
    case SIGNUP_ACTION_TYPES.AJAX_SIGNUP_SUBMIT_FAILED:
      return { ...state, error: payload, isLoading: false };
    default:
      return state;
  }
};
