import { useNavigate } from 'react-router-dom';

import {
  BackgroundImage,
  Body,
  DirectoryItemContainer,
} from './directory-item.styles';

const DirectoryItem = ({ category }) => {
  const { imageUrl, title, route, gradient, target } = category;
  const navigate = useNavigate();

  const onNavigateHandler = () => {
    if (target) {
      // open in new tab; external link
      return window.open(target, '_blank');
    }
    return navigate(route);
  }

  return (
    <DirectoryItemContainer onClick={onNavigateHandler}>
      <BackgroundImage imageUrl={imageUrl} gradient={gradient} />
      <Body>
        <h2>{title}</h2>
        <p>Click here</p>
      </Body>
    </DirectoryItemContainer>
  );
};

export default DirectoryItem;
