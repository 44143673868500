import { SIGNUP_ACTION_TYPES } from './signup.types';

import { createAction } from '../../utils/reducer/reducer.utils';

export const ajaxSignUpSubmitStart = (data) =>
  createAction(SIGNUP_ACTION_TYPES.AJAX_SIGNUP_SUBMIT_START, data);

export const ajaxSignUpSubmitSuccess = (outputArray) =>
  createAction(
    SIGNUP_ACTION_TYPES.AJAX_SIGNUP_SUBMIT_SUCCESS,
    outputArray
  );

export const ajaxSignUpSubmitFailed = (error) =>
  createAction(SIGNUP_ACTION_TYPES.AJAX_SIGNUP_SUBMIT_FAIL, error);