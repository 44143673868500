import { useEffect, useRef } from 'react';
import styled from 'styled-components';

export const ParentDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 50vh;
`;

export const SidebarDiv = styled.div`
  width: 100px;
  background-color: #f5f5f5;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

export const MainDiv = styled.div`
  flex: 1;
  padding: 20px;
  // if props has display set use that
  ${(props) => props.display && `display: ${props.display};`}
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  align-items: ${(props) => props.alignItems || 'flex-start'};
`;



export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
`;

export const ColFlex1 = styled.div`
  flex: 1;
`;





const StyledInput = styled.input`
  padding: 20px;
  font-size: 1.5rem;
  width: ${(props) => props.width || '100%'};
  margin-top: ${(props) => props.marginTop || '0px'};
  margin-bottom: ${(props) => props.marginBottom || '0px'};
`;

export function InputText(props) {
  const inputRef = useRef(null);
  useEffect(() => {
    // if props.defaultFocus is true
    if (props.defaultFocus === true) {
      inputRef.current.focus();
    }
    
  }, []);
  return <StyledInput ref={inputRef} {...props} autoComplete="off" />;
}




export const FieldHeading = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const OutputText = styled.div`
  font-size: 1.5rem;
  font-family: monospace;
  padding: 20px;
  border: 1px solid #000;
  background-color: #fffdd1;
`;


export const DivInputText = styled.div`
  font-size: 1.5rem;
  font-family: monospace;
  padding: 20px;
  border: 1px solid #000;
  background-color: #cce9ff;
`;


// <Components.HeaderDiv>
//           <Components.HeaderTitle>Signin Screen</Components.HeaderTitle>
//         </Components.HeaderDiv>

export const HeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const HeaderTitle = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
`;
