import { SCREEN_5_ACTION_TYPES } from "./screen_5.types";

export const SCREEN_5_INITIAL_STATE = {
  screen5: [],
  isLoading: false,
  error: null,
};

export const screen5Reducer = (
  state = SCREEN_5_INITIAL_STATE,
  action = {}
) => {
  const { type, payload } = action;

  switch (type) {
    case SCREEN_5_ACTION_TYPES.FETCH_SCREEN_5_START:
      return { ...state, isLoading: true };
    case SCREEN_5_ACTION_TYPES.FETCH_SCREEN_5_SUCCESS:
      return { ...state, screen5: payload, isLoading: false };
    case SCREEN_5_ACTION_TYPES.FETCH_SCREEN_5_FAILED:
      return { ...state, error: payload, isLoading: false };
    default:
      return state;
  }
};
