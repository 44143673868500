import { SCREEN_QUESTIONS_ACTION_TYPES } from './screen_questions.types';

import { createAction } from '../../utils/reducer/reducer.utils';

export const fetchScreenQuestionsStart = (data) =>
  createAction(SCREEN_QUESTIONS_ACTION_TYPES.FETCH_SCREEN_QUESTIONS_START, data);

export const fetchScreenQuestionsSuccess = (outputArray) =>
  createAction(
    SCREEN_QUESTIONS_ACTION_TYPES.FETCH_SCREEN_QUESTIONS_SUCCESS,
    outputArray
  );

export const fetchScreenQuestionsFailed = (error) =>
  createAction(SCREEN_QUESTIONS_ACTION_TYPES.FETCH_SCREEN_QUESTIONS_FAILED, error);


export const postQuestionStatusRequest = (data) =>
  createAction(SCREEN_QUESTIONS_ACTION_TYPES.POST_QUESTION_STATUS_REQUEST, data);

export const fetchScreenResultsUserAnswersStart = (data) =>
  createAction(SCREEN_QUESTIONS_ACTION_TYPES.FETCH_SCREEN_RESULTS_USER_ANSWERS_START, data);

export const fetchScreenResultsUserAnswersSuccess = (outputArray) =>
  createAction(
    SCREEN_QUESTIONS_ACTION_TYPES.FETCH_SCREEN_RESULTS_USER_ANSWERS_SUCCESS,
    outputArray
  );

export const fetchScreenResultsUserAnswersFailed = (error) =>
  createAction(SCREEN_QUESTIONS_ACTION_TYPES.FETCH_SCREEN_RESULTS_USER_ANSWERS_FAILED, error);
  