import styled from "styled-components";
import { Formik, Form, useField, useFormikContext } from "formik";


export const StyledErrorMessage = styled.div`
 font-size: 12px;
 color: var(--red-600);
//  width: 400px;
 margin-top: 0.25rem;
 &:before {
   content: "❌ ";
   font-size: 10px;
 }
 @media (prefers-color-scheme: dark) {
   color: var(--red-300);
 }
`;


export const TextInput = styled.input`
  // border: 2px solid var(--blue);
  padding:15px;
  // font-size: 16px;
  font-size: 1.2rem;

  border: 1px solid #ccc;
`;

export const Checkbox = styled.input.attrs({ type: "checkbox" })``;

export const Select = styled.select`
  border: 2px solid var(--blue);
  padding:10px;
  font-size: 16px;
  border: 1px solid #ccc;
`;

export const Label = styled.label`
  // margin-top: 1rem;
  font-size: 20px;
`;

export const Error = styled.div`
  font-size: 12px;
  color: var(--red-600);
  // width: 400px;
  margin-top: 0.25rem;
  &:before {
    content: "❌ ";
    font-size: 10px;
  }
  @media (prefers-color-scheme: dark) {
    color: var(--red-300);
  }
`;

export const MyTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div style={{
      display: 'flex',
      gap: 20,
      flexDirection: 'column',
      // alignItems: 'center',
      ...props.divStyle
    }}>
      <Label htmlFor={props.id || props.name}>{label}</Label>
      <TextInput {...field} {...props} />
      {meta.touched && meta.error ? (
        <Error>{meta.error}</Error>
      ) : null}
    </div>
  );
};

export const MyCheckbox = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props, type: "checkbox" });
  return (
    <>
      <Label className="checkbox">
        <Checkbox {...field} {...props} />
        {children}
      </Label>
      {meta.touched && meta.error ? (
        <Error>{meta.error}</Error>
      ) : null}
    </>
  );
};

export const MySelect = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div style={{
      display: 'flex',
      gap: 20,
      alignItems: 'center',
      ...props.divStyle
    }}>
      <Label htmlFor={props.id || props.name}>{label}</Label>
      <Select {...field} {...props} />
      {meta.touched && meta.error ? (
        <StyledErrorMessage>{meta.error}</StyledErrorMessage>
      ) : null}
    </div>
  );
};


export const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
justify-items: center;
// max-width: 300px;
width:100%;

`;


