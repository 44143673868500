import { Fragment, useEffect, useState } from 'react';

import Button from '../../components/button/button.component';
import * as Components from './signin.screen.styles';
import Spinner from '../../components/spinner/spinner.component';

// import { LoginForm } from './LoginForm.component';
import { LoginForm } from './LoginForm2.component';

// import useNavigate
import { useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';

import { ajaxSigninSubmitStart } from '../../store/signin/signin.action';

import { selectSignin, selectSigninIsLoading } from '../../store/signin/signin.selector';


const defaultFormFields = {
  txtUsername: '',
  txtPassword: ''
};

const Signin_Screen = (props) => {
  const signin = useSelector(selectSignin);

  const isLoading = useSelector(selectSigninIsLoading);

  const [formFields, setFormFields] = useState(defaultFormFields);
  const { txtUsername, txtPassword } = formFields;
  const dispatch = useDispatch();

  const navigate = useNavigate();


  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormFields({ ...formFields, [name]: value });
  };


  const handleSigninSubmit = async (event) => {
    event.preventDefault();

    if (txtUsername === '') {
      alert('Username is empty');
      return;
    }

    try {
      dispatch(ajaxSigninSubmitStart({ txtUsername, txtPassword, navigate }));
      // resetFormFields();
    } catch (error) {
      alert("error see console");
      console.log('encountered an error', error);
    }
  };

  useEffect(() => {
    try {
    }
    catch (error) {
      console.log('encountered an error', error);
    }
  }, []);




  return (
    <>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
      }}>
        <img style={{
          display: 'block',
        }} src="client_logo.png" />
        <h3>
          {/* Software | Hardware | Online MBA/MCA */}
        </h3>

      </div>
      <Components.MainDiv display="flex"
        justifyContent="center">

        <LoginForm handleSigninSubmit={handleSigninSubmit} handleChange={handleChange} />

      </Components.MainDiv>

    </ >
  );
};

export default Signin_Screen;
