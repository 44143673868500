import { SCREEN_6_ACTION_TYPES } from "./screen_6.types";

export const SCREEN_6_INITIAL_STATE = {
  screen6: [],
  isLoading: false,
  error: null,
};

export const screen6Reducer = (
  state = SCREEN_6_INITIAL_STATE,
  action = {}
) => {
  const { type, payload } = action;

  switch (type) {
    case SCREEN_6_ACTION_TYPES.FETCH_SCREEN_6_START:
      return { ...state, isLoading: true };
    case SCREEN_6_ACTION_TYPES.FETCH_SCREEN_6_SUCCESS:
      return { ...state, screen6: payload, isLoading: false };
    case SCREEN_6_ACTION_TYPES.FETCH_SCREEN_6_FAILED:
      return { ...state, error: payload, isLoading: false };
    default:
      return state;
  }
};
