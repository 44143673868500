import { SIGNIN_ACTION_TYPES } from "./signin.types";

export const SIGNIN_INITIAL_STATE = {
  // signin: [],
  isLoading: false,
  error: null,
};

export const signinReducer = (
  state = SIGNIN_INITIAL_STATE,
  action = {}
) => {
  const { type, payload } = action;

  switch (type) {
      
    case SIGNIN_ACTION_TYPES.AJAX_SIGNIN_SUBMIT_START:
      return { ...state, isLoading: true };
    case SIGNIN_ACTION_TYPES.AJAX_SIGNIN_SUBMIT_SUCCESS:
      return { ...state, isLoading: false };
    case SIGNIN_ACTION_TYPES.AJAX_SIGNIN_SUBMIT_FAILED:
      return { ...state, error: payload, isLoading: false };
    default:
      return state;
  }
};
