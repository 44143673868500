import * as Yup from "yup";
import { useNavigate, Navigate } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Button from '../../components/button/button.component';
import * as Components from './screen_questions.screen.styles';
import {
  fetchScreenQuestionsStart,
  postQuestionStatusRequest,
  fetchScreenResultsUserAnswersStart,
} from '../../store/screen_questions/screen_questions.action';

import {
  selectScreenQuestions,
  selectScreenQuestionsIsLoading,
  selectScreenResultsUserAnswers
}
  from '../../store/screen_questions/screen_questions.selector';
import Spinner from '../../components/spinner/spinner.component';
import MyContainer from './QuestionForm';
import OnOffToggle from './OnOffToggle';
import StyledSwitch from './StyledSwitch2';
import StyledMCQView from './StyledMCQView';

import AuthGuard from '../signin/AuthGuard';

const defaultFormFields = {
  arr: [],
  txtSearch: ''
};

const Screen_Questions_Screen = (props) => {
  const screenquestions = useSelector(selectScreenQuestions);

  const isLoading = useSelector(selectScreenQuestionsIsLoading);

  // selectScreenResultsUserAnswers
  const screenResultsUserAnswers = useSelector(selectScreenResultsUserAnswers);

  const [formFields, setFormFields] = useState(defaultFormFields);
  const { arr, txtSearch } = formFields;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    try {
      dispatch(fetchScreenQuestionsStart());
      dispatch(fetchScreenResultsUserAnswersStart());
    }
    catch (error) {
      console.log('encountered an error', error);
    }
  }, []);


  const myBgColor_checked = '#bfffc2';
  const myBgColor_unchecked = '#ffc4bd';

  const [backgroundMap, setBackgroundMap] = useState([]);

  // state for latest User Answers
  const [latestChosenOptions, setLatestChosenOptions] = useState({});

  const [correctAnswers, setCorrectAnswers] = useState({});

  const [myPositiveScore, setMyPositiveScore] = useState(0);
  const [myNegativeScore, setMyNegativeScore] = useState(0);


  useEffect(() => {
    // setBackgroundMap(screenquestions.map(item => item.status === 0 ? myBgColor_unchecked : myBgColor_checked));
    screenquestions.map(data => {
      // setBackgroundMap(prevState => {
      //   const newState = [...prevState];
      //   newState[data.id] = data.status === 1 ? myBgColor_checked : myBgColor_unchecked;
      //   return newState;
      // });

      setCorrectAnswers(prevState => {
        if (data.status === 0) {
          return { ...prevState, [data.id]: null };
        }
        return { ...prevState, [data.id]: data.correct_choice };
      });

    });
  }, [screenquestions]);

  useEffect(() => {
    const updatedLatestChosenOptions = {};

    for (const result of screenResultsUserAnswers) {
      const { question_id, chosen_option, created_at, status } = result;
      if (status === 0) {
        console.log("status is 0, skipping question_id:", question_id)
        continue;
      }
      if (question_id in updatedLatestChosenOptions) {
        const currentLatest = updatedLatestChosenOptions[question_id];
        const currentLatestCreatedAt = new Date(currentLatest.created_at);
        const resultCreatedAt = new Date(created_at);
        if (resultCreatedAt > currentLatestCreatedAt) {
          updatedLatestChosenOptions[question_id] = result;
        }
      } else {
        updatedLatestChosenOptions[question_id] = result;
      }
    }

    console.log("updatedLatestChosenOptions:", updatedLatestChosenOptions);

    // updatedLatestChosenOptions.map(data => {
    //   setBackgroundMap(prevState => {
    //     const newState = [...prevState];
    //     newState[data.id] = data.chosen_option === correctAnswers[data.id] ? myBgColor_checked : myBgColor_unchecked;
    //     return newState;
    //   });

    // });
    setLatestChosenOptions(updatedLatestChosenOptions);

    const updatedBackgroundMap = {};
    for (const [id, result] of Object.entries(updatedLatestChosenOptions)) {
      const isCorrect = result.chosen_option === correctAnswers[id];
      updatedBackgroundMap[id] = isCorrect ? myBgColor_checked : myBgColor_unchecked;
    }

    console.log("updatedBackgroundMap:", updatedBackgroundMap);
    setBackgroundMap(updatedBackgroundMap);


  }, [screenResultsUserAnswers, correctAnswers]);

  useEffect(() => {

    let positiveScore = 0, negativeScore = 0;
    for (const [key, value] of Object.entries(latestChosenOptions)) {
      // console.log("key:", key);
      // console.log("value:", value.chosen_option);
      // console.log("correctAnswers[key]:", correctAnswers[key]);
      // console.log(correctAnswers);
      // const chosenOptionChar = String.fromCharCode(64 + parseInt(value.chosen_option));
      if (value.chosen_option === correctAnswers[key]) {
        positiveScore += 1;
        // console lot key and value.chosen_option
        console.log("correct: ", key, value.chosen_option);
      }
      else {
        negativeScore += 1;
        console.log("wrong: ", key, value.chosen_option);

      }
    }
    setMyPositiveScore(positiveScore);
    setMyNegativeScore(negativeScore);
  }, [latestChosenOptions, correctAnswers]);



  return (
    <AuthGuard>
      <>

        <>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
          }}>
            <img style={{
              display: 'block',
            }} src="client_logo.png" />
            <h3>
              {/* Software | Hardware | Online MBA/MCA */}
            </h3>

          </div>
          <h1>
            Your Results And Explanations
          </h1>

          <div>
            {isLoading ? <Spinner /> : (
              <>

                {/* [{"question":{"question":"What motivates you in your career?","choices":[{"answer":"A","text":"A high salary"},{"answer":"B","text":"Job security"},{"answer":"C","text":"Opportunities for career growth and development"},{"answer":"D","text":"A good work-life balance"}],"correct_answer":"C","hint":"","explanation":"This question assesses the candidate's values and priorities. While all answer choices are important, the correct answer is C, as it reflects a candidate's desire to learn, develop, and advance in their career."}}] */}

                {/* check the count of updatedLatestChosenOptions if its >= 10, then show the results */}
                {Object.keys(latestChosenOptions).length >= 10 ? (

                  <>


                    <h2>
                      Your Correct Answers: {myPositiveScore} / {screenquestions.filter(item => item.status === 1).length}
                    </h2>

                    <h2>
                      Your Wrong Answers: {myNegativeScore} / {screenquestions.filter(item => item.status === 1).length}
                    </h2>


                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: '50px',

                    }}>
                      {screenquestions.map((item, index) => {
                        // if item.status is 0, then skip
                        if (item.status === 0) {
                          return null;
                        }
                        return (
                          <Components.QuestionAndSwitchContainer
                            key={index}
                            gap="20px"
                            backgroundColor={backgroundMap[item.id] || '#f0f0f0'}
                          >

                            <div style={{
                              display: 'flex',
                              flex: 1
                            }}>
                              <h3 style={{
                                fontSize: '2rem',
                                margin: '0',
                              }}>
                                <span style={{ fontWeight: 'bold', marginRight: '10px' }}>
                                  Question {index + 1}.
                                </span>
                              </h3>


                              {/* 
                          <StyledSwitch
                            label="Switch One"
                            large theme="success"
                            checked={item.status === 0 ? false : true}
                            updateSaga={updateSaga}
                            questionId={item.id}
                            marginTop="10px"
                          /> */}
                            </div>




                            <StyledMCQView
                              item={item}
                              question_index={index}
                            />

                            <h3 style={{
                              fontSize: '2rem',
                            }}>
                              {/* Your Answer: {latestChosenOptions[item.id]?.chosen_option} */}
                              {/* A=1, B=2, etc */}
                              Your Answer: {latestChosenOptions[item.id] ? String.fromCharCode(64 + latestChosenOptions[item.id]?.chosen_option) : 'no answer'}
                            </h3>




                            {/* <div style={{ marginLeft: '20px' }}>
                        <span style={{ fontWeight: 'bold', marginRight: '10px' }}>
                          Correct Answer:
                        </span>
                        <span>
                          {item.question.correct_answer}
                        </span>
                      </div> */}

                            {/* <div style={{ marginLeft: '20px' }}>
                        <span style={{ fontWeight: 'bold', marginRight: '10px' }}>
                          Hint:
                        </span>
                        <span>
                          {item.question.hint}
                        </span>
                      </div> */}

                            {/* 
                      <div style={{ marginLeft: '20px' }}>
                        <span style={{ fontWeight: 'bold', marginRight: '10px' }}>
                          Explanation:
                        </span>
                        <span>
                          {item.question.explanation}
                        </span>
                      </div> */}



                          </Components.QuestionAndSwitchContainer>
                        )
                      })}
                    </div>
                  </>

                ) : (
                  <h1>
                    Please answer at least 10 out of 15 questions to see your results.
                  </h1>
                )}

              </>
            )}
          </div>

        </>
      </>

    </AuthGuard >
  );
};

export default Screen_Questions_Screen;
