import { SCREEN_5_ACTION_TYPES } from './screen_5.types';

import { createAction } from '../../utils/reducer/reducer.utils';

export const fetchScreen5Start = (data) =>
  createAction(SCREEN_5_ACTION_TYPES.FETCH_SCREEN_5_START, data);

export const fetchScreen5Success = (outputArray) =>
  createAction(
    SCREEN_5_ACTION_TYPES.FETCH_SCREEN_5_SUCCESS,
    outputArray
  );

export const fetchScreen5Failed = (error) =>
  createAction(SCREEN_5_ACTION_TYPES.FETCH_SCREEN_5_FAILED, error);
