import { createSelector } from 'reselect';


const selectAuthenticationReducer = (state) => state.authentication;

export const selectIsAuthenticated = createSelector(
    [selectAuthenticationReducer],
    (authentication) => {
        if (authentication.session && authentication.session.access_token) {
            if (authentication.session.access_token && authentication.session.access_token.length > 0) {
                return true;
            }
        }
        return false;
    }
);

export const selectUserID = createSelector(
    [selectAuthenticationReducer],
    (authentication) => {
        if (authentication.user) {
            if (authentication.user.id && authentication.user.id.length > 0) {
                return authentication.user.id;
            }
        }
        return null;
    }
);



export const selectUserFirstName = createSelector(
    [selectAuthenticationReducer],
    (authentication) => {
        if (authentication.user) {
            if (authentication.user.user_metadata
                && authentication.user.user_metadata.first_name
                && authentication.user.user_metadata.first_name.length > 0) {
                return authentication.user.user_metadata.first_name;
            }
        }
        return null;
    }
);

// const selectGen01Reducer = (state) => state.screen8;

// export const selectGen01 = createSelector(
//   [selectGen01Reducer],
//   (screen8) => {
//     return screen8.gen01
//   }
// );

// export const selectGen01IsLoading = createSelector(
//   [selectGen01Reducer],
//   (screen8) => screen8.isLoading
// );
