import { takeEvery, takeLatest, all, call, put } from 'redux-saga/effects';
import { API_URL } from '../config';
import { SCREEN_5_ACTION_TYPES } from './screen_5.types';

import {
  fetchScreen5Success,
  fetchScreen5Failed,
} from './screen_5.action';

// import axios
import axios from 'axios';

export function* fetchScreen5Async() {
  console.log("need to initiate fetchScreen5Async but wont use any params");

  try {

    const response = yield call(axios.get, `${API_URL}/screen_5`);
    
    yield put(fetchScreen5Success(response.data));
  } catch (error) {
    yield put(fetchScreen5Failed(error)); 
  }
}

export function* onfetchScreen5() {
  yield takeEvery(
    SCREEN_5_ACTION_TYPES.FETCH_SCREEN_5_START,
    fetchScreen5Async
  );
}

export function* screen5Saga() {
  yield all([call(onfetchScreen5)]);
}