import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Signin_Screen from '../../screens/signin/signin.screen';

const Signin = () => {
  const dispatch = useDispatch();

  useEffect(() => {

  }, []);

  return (
    <Routes>
      <Route index element={<Signin_Screen  />} />
    </Routes>
  );
};

export default Signin;
