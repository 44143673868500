import { useEffect, useRef } from 'react';
import styled from 'styled-components';

export const ParentDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 50vh;
`;

export const SidebarDiv = styled.div`
  width: 100px;
  background-color: #f5f5f5;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

export const MainDiv = styled.div`
  flex: 1;
  padding: 20px;
`;



export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
`;

export const ColFlex1 = styled.div`
  flex: 1;
`;





const StyledInput = styled.textarea`
  padding: 20px;
  font-size: 1.5rem;
  width: 100%;
  min-height: 500px;
`;

export function InputText(props) {
  const inputRef = useRef(null);
  useEffect(() => {
      inputRef.current.focus();
  }, []);
  return <StyledInput ref={inputRef} {...props} />;
}




export const FieldHeading = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const OutputText = styled.div`
  font-size: 1.5rem;
  font-family: monospace;
  padding: 20px;
  border: 1px solid #000;
  background-color: #ffffae;
`;