import { takeEvery, takeLatest, all, call, put } from 'redux-saga/effects';


// import { createClient } from '@supabase/supabase-js';

import { supabase } from '../config';

import { SIGNUP_ACTION_TYPES } from './signup.types';

import {
  ajaxSignUpSubmitSuccess,
  ajaxSignUpSubmitFailed
} from './signup.action';

import {
  authenticationSuccess
} from '../authentication/authentication.action';



export function* ajaxSignUpSubmitAsync(action) {

  try {

    // console.log("supabase", supabase);

    // log the action
    // console.log("ajaxSignUpSubmitAsync saga", action);
    const tempVar = {
      email: action.payload.email,
      password: action.payload.password,
      options: {
        data: {
          first_name: action.payload.firstName,
          last_name: action.payload.lastName,
          phone_number: action.payload.phone_number,
          education: action.payload.education,
        }
      },
    };

    // yield calling a nested object is failing weirdly
    // this works -->    const { data, error } = yield call([supabase.auth, supabase.auth.signUp], tempVar);
    // but this fails -->    const { data, error } = yield call([supabase.auth.signUp], tempVar);
    // and this fails -->    const { data, error } = yield call(supabase.auth.signUp, tempVar);
    // https://redux-saga.js.org/docs/api/#callfn-args
    
    const { data, error } = yield call([supabase.auth, supabase.auth.signUp], tempVar);


    // const response = yield call(axios.post, 'http://localhost:8080/signUp', action.payload);

    // yield put(ajaxSignUpSubmitSuccess(response.data));
    yield put(ajaxSignUpSubmitSuccess(data));
    yield put(authenticationSuccess(data));

    action.payload.navigate('/test');
    // https://stackoverflow.com/questions/70881320/redirect-to-route-from-saga-using-react-router-v6



  } catch (error) {
    console.log(error);
    yield put(ajaxSignUpSubmitFailed(error));
  }
}


export function* onAjaxSignUpSubmit() {
  yield takeLatest(
    SIGNUP_ACTION_TYPES.AJAX_SIGNUP_SUBMIT_START,
    ajaxSignUpSubmitAsync
  );
}

export function* signupSaga() {
  yield all([
    call(onAjaxSignUpSubmit)
  ]);
}
