import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Screen_Results_Screen from '../../screens/screen_questions/screen_results.screen';

const Screen_Results = () => {
  const dispatch = useDispatch();

  useEffect(() => {
  }, []);

  return (
    <Routes>
      <Route index element={<Screen_Results_Screen abc='View Text' />} />
    </Routes>
  );
};

export default Screen_Results;
