import { takeEvery, takeLatest, all, call, put } from 'redux-saga/effects';
import { API_URL } from '../config';
import { SCREEN_6_ACTION_TYPES } from './screen_6.types';
import { supabase } from '../config';

// import { selectUserID } from '../authentication/authentication.selector';


import {
  fetchScreen6Success,
  fetchScreen6Failed,
} from './screen_6.action';

// import axios
import axios from 'axios';

export function* fetchScreen6Async() {

  try {

    // const response = yield call(axios.get, `${API_URL}/screen_6`);


    let { data, error } = yield call(async (supabase) => {
      const ans = await supabase
        .from('intg_test_questions')
        .select('*')
        .eq('test_id', 1)
        .eq('status', 1)
      // console.log("ans", ans)
      return ans;

    }, supabase);



    yield put(fetchScreen6Success(data));
  } catch (error) {
    yield put(fetchScreen6Failed(error));
  }
}

export function* onfetchScreen6() {
  yield takeEvery(
    SCREEN_6_ACTION_TYPES.FETCH_SCREEN_6_START,
    fetchScreen6Async
  );
}




export function* postAnswerRequestAsync(action) {

  try {

    // const response = yield call(axios.get, `${API_URL}/screen_6`);
    const tempVar = {
      user_id: action.payload.user_id,
      test_id: action.payload.test_id,
      question_id: action.payload.question_id,
      chosen_option: action.payload.chosen_option,
    };



    let { data, error } = yield call(async (supabase, tempVar) => {
      console.log(tempVar)
      const { data, error } = await supabase
        .from('intg_user_answers')
        .insert([tempVar])

        console.log("data", data)
        console.log("error", error)

      return data;
    }, supabase, tempVar);



    yield put(fetchScreen6Success(data));
  } catch (error) {
    yield put(fetchScreen6Failed(error));
  }
}

export function* onpostAnswerRequest() {
  yield takeEvery(
    SCREEN_6_ACTION_TYPES.POST_ANSWER_REQUEST,
    postAnswerRequestAsync
  );
}

export function* screen6Saga() {
  yield all([
    call(onfetchScreen6),
    call(onpostAnswerRequest)
  ]);
}