
import { AUTHENTICATION_ACTION_TYPES } from "./authentication.types";

export const AUTHENTICATION_INITIAL_STATE = {
  user: {},
  session:{},
  isLoading: false,
  error: null,
};

export const authenticationReducer = (
  state = AUTHENTICATION_INITIAL_STATE,
  action = {}
) => {
  const { type, payload } = action;

  switch (type) {
      
    case AUTHENTICATION_ACTION_TYPES.AUTHENTICATION_SUCCESS:
      return { ...state, user: payload.user, session: payload.session, isLoading: false };
    case AUTHENTICATION_ACTION_TYPES.AUTHENTICATION_FAILED:
      return { ...state, error: payload, isLoading: false };
    default:
      return state;
  }
};
