import { all, call } from 'redux-saga/effects';

import { screen5Saga } from './screen_5/screen_5.saga';
import { screen6Saga } from './screen_6/screen_6.saga';
import { screen8Saga } from './screen_8/screen_8.saga';
import { signinSaga } from './signin/signin.saga';
import { signupSaga } from './signup/signup.saga';
// import { authenticationSaga } from './authentication/authentication.saga';
import { screenquestionsSaga } from './screen_questions/screen_questions.saga';

export function* rootSaga() {
  yield all([
    call(screen5Saga),
    call(screen6Saga),
    call(screen8Saga),
    call(signinSaga),
    call(signupSaga),
    call(screenquestionsSaga),

  ]);
}
