import { GEN01_ACTION_TYPES } from './screen_8.types';

import { createAction } from '../../utils/reducer/reducer.utils';

export const fetchGen01Start = (gen01InputText) =>
  createAction(GEN01_ACTION_TYPES.FETCH_SCREEN_8_GEN01_START, gen01InputText);

export const fetchGen01Success = (outputArray) =>
  createAction(
    GEN01_ACTION_TYPES.FETCH_SCREEN_8_GEN01_SUCCESS,
    outputArray
  );

export const fetchGen01Failed = (error) =>
  createAction(GEN01_ACTION_TYPES.FETCH_SCREEN_8_GEN01_FAILED, error);
