import { GEN01_ACTION_TYPES } from './screen_8.types';

export const GEN01_INITIAL_STATE = {
  gen01: [],
  isLoading: false,
  error: null,
};

export const screen8Reducer = (
  state = GEN01_INITIAL_STATE,
  action = {}
) => {
  const { type, payload } = action;

  switch (type) {
    case GEN01_ACTION_TYPES.FETCH_SCREEN_8_GEN01_START:
      return { ...state, isLoading: true };
    case GEN01_ACTION_TYPES.FETCH_SCREEN_8_GEN01_SUCCESS:
      return { ...state, gen01: payload, isLoading: false };
    case GEN01_ACTION_TYPES.FETCH_SCREEN_8_GEN01_FAILED:
      return { ...state, error: payload, isLoading: false };
    default:
      return state;
  }
};
