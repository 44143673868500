
import gfm from 'remark-gfm'

import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coldarkDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';
// https://github.com/react-syntax-highlighter/react-syntax-highlighter/issues/230
// use "cjs" instead of "esm" for react-syntax-highlighter prism

//  a11yDark, atomDark, base16AteliersulphurpoolLight, cb, coldarkCold, 
// coldarkDark, coy, coyWithoutShadows, darcula, dark, dracula, 
// duotoneDark, duotoneEarth, duotoneForest, duotoneLight, duotoneSea, 
// duotoneSpace, funky, ghcolors, gruvboxDark, gruvboxLight, holiTheme, 
// hopscotch, lucario, materialDark, materialLight, materialOceanic,
//  nightOwl, nord, okaidia, oneDark, oneLight, pojoaque, prism, 
// shadesOfPurple, solarizedDarkAtom, solarizedlight, synthwave84, 
// tomorrow, twilight, vs, vscDarkPlus, xonokai, zTouch

// preview syntax theme colors here 
// https://react-syntax-highlighter.github.io/react-syntax-highlighter/demo/

import styled from 'styled-components';


const AnswerCore = styled(ReactMarkdown)`
height: auto;

overflow: hidden;
overflow-x: auto;
font-family: ${(props) => props.config.fontFamily};
font-size: ${(props) => props.config.answerFontSize};
max-width:${(props) => props.config.answerMaxWidth || '42vw'};
@media (max-width: 768px) {
    max-width:85vw;
}
`;

const Answer = ({ children, config }) => {
  return (
    <AnswerCore
      config={config}
      children={children}
      remarkPlugins={[gfm]}
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || '')
          return !inline && match ? (
            <SyntaxHighlighter
              {...props}
              children={String(children).replace(/\n$/, '')}
              style={coldarkDark}
              language={match[1]}
              PreTag="div"
            />
          ) : (
            <code {...props} className={className}>
              {children}
            </code>
          )
        }
      }} />
  );
};

export default Answer;