import { useEffect, useId, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { postAnswerRequest } from '../../store/screen_6/screen_6.action';
import { selectUserID } from '../../store/authentication/authentication.selector';
import { useSelector } from 'react-redux';

export const ParentDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 50vh;
`;

export const SidebarDiv = styled.div`
  width: 100px;
  background-color: #f5f5f5;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

export const MainDiv = styled.div`
  flex: 1;
  // padding: 20px;
`;



export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
`;

export const ColFlex1 = styled.div`
  flex: 1;
`;





const StyledInput = styled.input`
  padding: 20px;
  font-size: 1.5rem;
  width: 100%;
`;

export function InputText(props) {
  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current.focus();
  }, []);
  return <StyledInput ref={inputRef} {...props} />;
}




export const FieldHeading = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const OutputText = styled.div`
  font-size: 1.5rem;
  font-family: monospace;
  padding: 20px;
  border: 1px solid #000;
  background-color: #fffdd1;
`;


export const DivInputText = styled.div`
  font-size: 1.5rem;
  font-family: monospace;
  padding: 20px;
  border: 1px solid #000;
  background-color: #cce9ff;
`;


export const FixedWidthDiv = styled.div`
display: flex;
flex-direction: column;
// align-items: center;
width:100%;
max-width: 500px;
`;


export const ChoiceItemDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;

  &:hover {
    cursor: pointer;
    background-color: #0000cc;
    color: #fff;
  }
`;







export const ChoiceState = ({ choice, index, selected, onChange }) => {
  const myStyle = {
    cursor: 'pointer',
    backgroundColor: selected ? '#0000cc' : 'transparent',
    color: selected ? '#fff' : '#000',
  }

  return (
    <ChoiceItemDiv style={myStyle} onClick={() => onChange(choice.answer)}>
      <input
        type="radio"
        name={`q_${index}_choice`}
        id={`q_${index}_choice_${choice.answer}`}
        checked={selected}
        readOnly
      />
      <div style={{
        fontWeight: 'bold',
        marginRight: '10px',
        fontSize: '1.5rem',
      }}>
        {choice.answer}.
      </div>
      <div style={{
        // fontWeight: 'bold',
        marginRight: '10px',
        fontSize: '1.5rem',
      }}>
        {choice.text}
      </div>
    </ChoiceItemDiv>
  );
};

export const Question = ({ item, index }) => {
  const [selectedChoice, setSelectedChoice] = useState('');
  const question_index = index;
  const dispatch = useDispatch();
  const userID = useSelector(selectUserID);

  const handleChoiceChange = (choice) => {
    setSelectedChoice(choice);
    // const tempVar = {
    //   user_id: action.payload.userId,
    //   test_id: action.payload.testId,
    //   question_id: action.payload.questionId,
    //   chosen_option: action.payload.chosenOption,
    // };
    // convert A to 1, B to 2, C to 3, D to 4
    let choiceNum = choice.charCodeAt(0) - 64;

    dispatch(postAnswerRequest({
      user_id: userID,
      test_id: item.test_id,
      question_id: item.id,
      chosen_option: choiceNum,
    }));
  };

  const choices = ["A", "B", "C", "D"];

  const choiceComponents = choices.map((choice) => {
    return (
      <ChoiceState
        key={question_index + "_" + choice}
        choice={{ "answer": choice, "text": item['choice_' + choice.toLowerCase()] }}
        index={question_index}
        selected={selectedChoice === choice}
        onChange={handleChoiceChange}
      />
    );
  });

  return (
    <>
      <h3 style={{
        fontSize: '2rem',
        display: 'flex',
      }}>
        <div style={{ fontWeight: 'bold', marginRight: '10px' }}>
          {question_index + 1}.
        </div>
        <div>
          {item.question}
        </div>
      </h3>

      <div style={{ marginLeft: '20px' }}>

        {/* <ChoiceState
          key={question_index + "_A"}
          choice={{ "answer": "A", "text": item['choice_a'] }}
          index={question_index}
          selected={selectedChoice === "A"}
          onChange={handleChoiceChange}
        /> */}
        {choiceComponents}

        {/* {item.question.choices.map((choice, index2) => {
          return (
            <ChoiceState
              key={index2}
              choice={choice}
              index={index}
              selected={selectedChoice === choice.answer}
              onChange={handleChoiceChange}
            />
          )
        })} */}
      </div>
    </>
  );
};
