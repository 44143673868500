import { SCREEN_QUESTIONS_ACTION_TYPES } from "./screen_questions.types";

export const SCREEN_QUESTIONS_INITIAL_STATE = {
  screenquestions: [],
  screenresultsuseranswers: [],
  isLoading: false,
  error: null,
};

export const screenquestionsReducer = (
  state = SCREEN_QUESTIONS_INITIAL_STATE,
  action = {}
) => {
  const { type, payload } = action;

  switch (type) {
    case SCREEN_QUESTIONS_ACTION_TYPES.FETCH_SCREEN_QUESTIONS_START:
      return { ...state, isLoading: true };
    case SCREEN_QUESTIONS_ACTION_TYPES.FETCH_SCREEN_QUESTIONS_SUCCESS:
      return { ...state, screenquestions: payload, isLoading: false };
    case SCREEN_QUESTIONS_ACTION_TYPES.FETCH_SCREEN_QUESTIONS_FAILED:
      return { ...state, error: payload, isLoading: false };
    case SCREEN_QUESTIONS_ACTION_TYPES.FETCH_SCREEN_RESULTS_USER_ANSWERS_START:
      return { ...state, isLoading: true };
    case SCREEN_QUESTIONS_ACTION_TYPES.FETCH_SCREEN_RESULTS_USER_ANSWERS_SUCCESS:
      return { ...state, screenresultsuseranswers: payload, isLoading: false };
    case SCREEN_QUESTIONS_ACTION_TYPES.FETCH_SCREEN_RESULTS_USER_ANSWERS_FAILED:
      return { ...state, error: payload, isLoading: false };
    default:
      return state;
  }
};
