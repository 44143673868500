import { createSelector } from 'reselect';

const selectGen01Reducer = (state) => state.screen8;

export const selectGen01 = createSelector(
  [selectGen01Reducer],
  (screen8) => {
    return screen8.gen01
  }
);

export const selectGen01IsLoading = createSelector(
  [selectGen01Reducer],
  (screen8) => screen8.isLoading
);
