import { takeLatest, all, call, put } from 'redux-saga/effects';

import { GEN01_ACTION_TYPES } from './screen_8.types';

import {
  fetchGen01Success,
  fetchGen01Failed,
} from './screen_8.action';

// import axios
import axios from 'axios';

export function* fetchGen01Async(actionObj) {
  console.log("need to initiate fetchGen01Async saga", actionObj.payload)

  try {
    console.log("fetchGen01Async saga", actionObj.payload)
    const response = yield call(axios.post, 'http://localhost:8080/gen01', actionObj.payload);
    
    yield put(fetchGen01Success(response.data));
  } catch (error) {
    yield put(fetchGen01Failed(error));
  }
}

export function* onfetchGen01() {
  yield takeLatest(
    GEN01_ACTION_TYPES.FETCH_SCREEN_8_GEN01_START,
    fetchGen01Async
  );
}

export function* screen8Saga() {
  yield all([call(onfetchGen01)]);
}
