import { SIGNIN_ACTION_TYPES } from './signin.types';

import { createAction } from '../../utils/reducer/reducer.utils';

export const ajaxSigninSubmitStart = (data) =>
  createAction(SIGNIN_ACTION_TYPES.AJAX_SIGNIN_SUBMIT_START, data);

export const ajaxSigninSubmitSuccess = (outputArray) =>
  createAction(
    SIGNIN_ACTION_TYPES.AJAX_SIGNIN_SUBMIT_SUCCESS,
    outputArray
  );

export const ajaxSigninSubmitFailed = (error) =>
  createAction(SIGNIN_ACTION_TYPES.AJAX_SIGNIN_SUBMIT_FAIL, error);