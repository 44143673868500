import React, { useState } from 'react';
import styled from 'styled-components';

const ToggleButton = styled.button`
  width: 60px;
  height: 30px;
  padding: 2px;
  border-radius: 30px;
  background-color: ${({ isOn }) => isOn ? '#4CAF50' : '#dcdcdc'};
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:after {
    content: '';
    display: block;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    margin-left: ${({ isOn }) => isOn ? '30px' : '0'};
    background-color: #fff;
    transition: margin 0.3s ease-in-out;
    box-shadow: 0 0 2px rgba(0,0,0,0.3);
  }
`;

const OnOffToggle = () => {
  const [isOn, setIsOn] = useState(false);

  const handleToggle = () => {
    setIsOn(prevState => !prevState);
  };

  return (
    <ToggleButton isOn={isOn} onClick={handleToggle}>
      <span>{isOn ? 'ON' : 'OFF'}</span>
    </ToggleButton>
  );
};

export default OnOffToggle;