import { takeEvery, takeLatest, all, call, put } from 'redux-saga/effects';

import { SIGNIN_ACTION_TYPES } from './signin.types';

import { supabase } from '../config';

import {
  ajaxSigninSubmitSuccess,
  ajaxSigninSubmitFailed
} from './signin.action';

import {
  authenticationSuccess
} from '../authentication/authentication.action';



export function* ajaxSigninSubmitAsync(action) {

  try {

    const tempVar = {
      email: action.payload.email,
      password: action.payload.password,
      
    };

    
    // yield calling a nested object is failing weirdly
    // this works -->    const { data, error } = yield call([supabase.auth, supabase.auth.signUp], tempVar);
    // but this fails -->    const { data, error } = yield call([supabase.auth.signUp], tempVar);
    // and this fails -->    const { data, error } = yield call(supabase.auth.signUp, tempVar);
    // https://redux-saga.js.org/docs/api/#callfn-args
    
    const { data, error } = yield call([supabase.auth, supabase.auth.signInWithPassword], tempVar);


    // console.log(action)
    

    // yield put(ajaxSigninSubmitSuccess(data));
    yield put(authenticationSuccess(data));

    // console.log(data)
    // console.log(error)

    action.payload.navigate('/test');


  } catch (error) {
    // console.log(error)
    yield put(ajaxSigninSubmitFailed(error));
  }
}


export function* onAjaxSigninSubmit() {
  yield takeLatest(
    SIGNIN_ACTION_TYPES.AJAX_SIGNIN_SUBMIT_START,
    ajaxSigninSubmitAsync
  );
}

export function* signinSaga() {
  yield all([
    call(onAjaxSigninSubmit)
  ]);
}
