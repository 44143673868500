import { createSelector } from 'reselect';

const selectSigninReducer = (state) => state.signin;

export const selectSignin = createSelector(
  [selectSigninReducer],
  (signinSlice) => {
    console.log('signinSlice.signin', signinSlice.signin);
    return signinSlice.signin;
  }
);

export const selectSigninIsLoading = createSelector(
  [selectSigninReducer],
  (signinSlice) => signinSlice.isLoading
);
