export const SCREEN_QUESTIONS_ACTION_TYPES = {
  FETCH_SCREEN_QUESTIONS_START: 'screen_questions/FETCH_SCREEN_QUESTIONS_START',
  FETCH_SCREEN_QUESTIONS_SUCCESS: 'screen_questions/FETCH_SCREEN_QUESTIONS_SUCCESS',
  FETCH_SCREEN_QUESTIONS_FAILED: 'screen_questions/FETCH_SCREEN_QUESTIONS_FAILED',

  FETCH_SCREEN_RESULTS_USER_ANSWERS_START: 'screen_questions/FETCH_SCREEN_RESULTS_USER_ANSWERS_START',
  FETCH_SCREEN_RESULTS_USER_ANSWERS_SUCCESS: 'screen_questions/FETCH_SCREEN_RESULTS_USER_ANSWERS_SUCCESS',
  FETCH_SCREEN_RESULTS_USER_ANSWERS_FAILED: 'screen_questions/FETCH_SCREEN_RESULTS_USER_ANSWERS_FAILED',



  POST_QUESTION_STATUS_REQUEST: 'screen_questions/POST_QUESTION_STATUS_REQUEST',
};
