import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactDOM from "react-dom";
import { Formik, Form, useField, useFormikContext } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import Button from "../../components/button/button.component";
import { createClient } from "@supabase/supabase-js";

import * as Components from './signup.screen.styles';

import { ajaxSignUpSubmitStart } from '../../store/signup/signup.action';
import { useNavigate, Navigate } from 'react-router-dom';
import { selectIsAuthenticated } from '../../store/authentication/authentication.selector';
import { user_organization_name, user_organization_app_heading } from "../../store/config";


const Error = styled.div`
  font-size: 18px;
  color: #e30e4e;
  // width: 400px;
  margin-top: 0.25rem;
  &:before {
    content: "❌ ";
    font-size: 10px;
  }
  @media (prefers-color-scheme: dark) {
    color: var(--red-300);
  }
`;


// const StyledErrorMessage = styled.div`
//  font-size: 16px;
//   color: #e30e4e;
// //  width: 400px;
//  margin-top: 0.25rem;
//  &:before {
//    content: "❌ ";
//    font-size: 10px;
//  }
//  @media (prefers-color-scheme: dark) {
//    color: var(--red-300);
//  }
// `;


const TextInput = styled.input`
  // border: 2px solid var(--blue);
  padding:15px;
  // font-size: 16px;
  font-size: 1.2rem;

  border: 1px solid #ccc;
`;

const Checkbox = styled.input.attrs({ type: "checkbox" })``;

const Select = styled.select`
  border: 2px solid var(--blue);
  padding:15px;
  font-size: 1.2rem;
  border: 1px solid #ccc;
`;

const Label = styled.label`
  // margin-top: 1rem;
  font-size: 20px;
`;

const MyTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div style={{
      display: 'flex',
      gap: 20,
      flexDirection: 'column',
      // alignItems: 'center',
      ...props.divStyle
    }}>
      <Label htmlFor={props.id || props.name}>{label}</Label>
      <TextInput {...field} {...props} />
      {meta.touched && meta.error ? (
        <Error>{meta.error}</Error>
      ) : null}
    </div>
  );
};

const MyCheckbox = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props, type: "checkbox" });
  return (
    <>
      <Label className="checkbox">
        <Checkbox {...field} {...props} />
        {children}
      </Label>
      {meta.touched && meta.error ? (
        <Error>{meta.error}</Error>
      ) : null}
    </>
  );
};

const MySelect = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div style={{
      display: 'flex',
      gap: 20,
      flexDirection: 'column',
      // alignItems: 'center',
      ...props.divStyle
    }}>
      <Label htmlFor={props.id || props.name}>{label}</Label>
      <Select {...field} {...props} />

      {meta.error ? (

        <Error>{meta.error}</Error>
      ) : null}
    </div>
  );
};


const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
justify-items: center;
// max-width: 500px;
width:100%;

`;



export const SignupForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(selectIsAuthenticated);



  const MyContainer = (<Container>

    <Formik
      initialValues={{
        email: "",
        password: "",

        firstName: "",
        lastName: "",

        phone: "",
        acceptedTerms: false,
        // jobType: ""
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email("Invalid email addresss`")
          .required("Valid Email is Required"),
        password: Yup.string()
          .min(8, "Must be 8 characters or more")
          .required("Valid Password is Required"),

        firstName: Yup.string()
          .max(15, "Must be 15 characters or less")
          .required("First Name is Required"),
        lastName: Yup.string()
          .max(20, "Must be 20 characters or less")
          .required("Last Name is Required"),

        phone: Yup.string()
          // .max(10, "Must be 20 characters or less")
          // must be 10 digits
          .matches(/^[0-9]+$/, "Must be only digits")
          .min(10, "Must be 10 digits")
          .max(10, "Must be 10 digits")
          .required("Required"),
        acceptedTerms: Yup.boolean()
          .required("Required")
          .oneOf([true], "To sign up, you must agree to share your basic contact information"),



        education: Yup.string()
          .oneOf(
            ["BCA", "BSc-Computers", "BCom-Computers", "BSc-Non-Computers", "BCom-Non-computers", "BA", "BBA", "Not-listed-here"],
            "Education is not selected",
          )
          .required("Education field is Required")
      })}
      onSubmit={async (formDataInJson, { setSubmitting }) => {
        // await new Promise(r => {
        //   // button clicked + random value
        //   console.log("button clicked" + Math.random());
        //   setTimeout(r, 2000)
        // });
        // add , navigate into formDataInJson
        // formDataInJson.navigate = navigate;
        // console.log("formDataInJson", formDataInJson);
        dispatch(ajaxSignUpSubmitStart({
          ...formDataInJson,
          navigate
        }));

        setSubmitting(true);

        // const supabase = createClient(supabaseUrl, supabaseKey);

        // console.log("supabase", supabase);

        // log the action
        // console.log("ajaxSignUpSubmitAsync saga", action);

        // const { data, error } = await supabase.auth.signUp({
        //   email: formDataInJson.email,
        //   password: formDataInJson.password,
        //   options: {
        //     data: {
        //       first_name: formDataInJson.firstName,
        //       last_name: formDataInJson.lastName,
        //       phone_number: formDataInJson.phone_number,
        //     }
        //   },
        // });

        // console.log("data", data);
        // console.log("error", error);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 20,
            // padding: 20,
            maxWidth: 400,
            flex: 1,
          }}>
            <h1>
              {/* {user_organization_name}:  */}
              {user_organization_app_heading}
            </h1>

            <Components.HeaderDiv>
              <Components.HeaderTitle>Sign Up</Components.HeaderTitle>
            </Components.HeaderDiv>

            <MyTextInput
              label="Email Address"
              name="email"
              type="email"
              placeholder="Eg: sujatha.email@example.com"
              tabIndex="1"
            />

            {/* <Components.InputText type="text"
              marginTop="20px"
              marginBottom="20px" 
              width="100%"
              
              label="Email Address"
              name="email"
              type="email"
              placeholder="Email Address" 
              // onChange={props.handleChange}
              defaultFocus={true}
            /> */}


            {/* password */}
            <MyTextInput
              label="Password"
              name="password"
              type="password"
              placeholder="********"
            />

            <MyTextInput
              label="First Name"
              name="firstName"
              type="text"
              placeholder="Eg: Sujatha"
              divStyle={{}}
            />
            <MyTextInput
              label="Last Name"
              name="lastName"
              type="text"
              placeholder="Eg: Kumar"
            />

            <MyTextInput
              label="Phone Number"
              name="phone"
              type="text"
              placeholder="Eg: 9111222333 (10 digits)"
            />
            <MySelect label="Education" name="education">
              <option value="">Select from list</option>

              <option value="BCA">BCA</option>
              <option value="BSc-Computers">BSc Computers</option>
              <option value="BCom-Computers">BCom Computers</option>
              <option value="BSc-Non-Computers">BSc (Non-computers)</option>
              <option value="BCom-Non-computers">BCom (Non-computers)</option>
              <option value="BA">BA</option>
              <option value="BBA">BBA</option>
              <option value="Not-listed-here">Not listed here</option>

            </MySelect>

            <MyCheckbox name="acceptedTerms">
              I agree to share my basic contact information
            </MyCheckbox>
            {/* <div style={{
              maxWidth: 300,
            }}> */}
            <Button
              hoverBackgroundColor="#34b800" hoverForegroundColor="white"
              type="submit"
              disabled={isSubmitting}
            >Submit</Button>
            {/* </div> */}
          </div>




          <hr style={{
            width: '100%',
            marginTop: 20,
            marginBottom: 20,
          }} />
          <Button

            hoverBackgroundColor="#0556b3" hoverForegroundColor="white"
            // on click, navigate to signup page
            onClick={() => navigate('/signin')}
          >
            Sign In (Existing Users)
          </Button>
        </Form>
      )}

    </Formik>

  </Container>);





  return (
    <>

      {/* its right to place this logo code here */}
      <div style={{
        display: 'block',
        flexDirection: 'row',
      }}>
        <img style={{
          display: 'block',
        }} src="client_logo.png" />
        <h3>
          {/* Software | Hardware | Online MBA/MCA */}
        </h3>

      </div>

      {isAuthenticated ? (
        <Navigate to="/" />
      ) : (
        <>
          {MyContainer}
        </>
      )}
    </>
  );



};