import styled from "styled-components";

const QuestionContainer = styled.div`
  // margin: 10px 0;

  h3 {
    display: flex;
    margin-bottom: 10px;
    font-size: 2rem;
    margin-top:0;

    span:first-child {
      font-weight: bold;
      margin-right: 10px;
    }
  }

  div {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    // grid-column-gap: 10px;
    // grid-row-gap: 10px;

    p {
      font-size: 1.5rem;
      margin-bottom: 0;
      padding: 10px;
    }

    p.correct {
      background-color: #ffffff;
    }
  }
`;

const StyledMCQView = ({ item, question_index }) => {
  return (
    <QuestionContainer>
      <h3>
        {/* <span style={{ fontWeight: 'bold', marginRight: '10px' }}>
          {question_index + 1}.
        </span> */}
        <span>
          {item.question}
        </span>
      </h3>
      <div>
        <p className={item.correct_choice === 1 ? 'correct' : ''}><strong>Choice A: </strong>{item.choice_a}</p>
        <p className={item.correct_choice === 2 ? 'correct' : ''}><strong>Choice B: </strong>{item.choice_b}</p>
        <p className={item.correct_choice === 3 ? 'correct' : ''}><strong>Choice C: </strong>{item.choice_c}</p>
        <p className={item.correct_choice === 4 ? 'correct' : ''}><strong>Choice D: </strong>{item.choice_d}</p>
      </div>
      <div>
        <p>
          <strong>Correct Answer: </strong>
          {/* {item.correct_choice} */}
          {/* convert A = 1, B=2 ,etc */}
          {String.fromCharCode(item.correct_choice + 64)}
        </p>
        <p>
          <strong>Hint: </strong>
          {item.hint}
        </p>
        <p>
          <strong>Explanation: </strong>
          {item.explanation}
        </p>
      </div>
    </QuestionContainer>
  )
}

export default StyledMCQView;