import DirectoryItem from '../directory-item/directory-item.component';

import { DirectoryContainer } from './directory.styles';

const categories = [
  {
    id: 1,
    title: 'Take the Psychometric Test',
    imageUrl: 'bg/1.jpg',
    // gradient: 'linear-gradient(to right top, #051937, #004d7a, #008793)',
    gradient: 'linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(48,140,188,1) 50%, rgba(69,162,252,1) 100%)',
    route: 'test',
  },
  {
    id: 2,
    title: 'View Your Results, Analysis and Explanations',
    imageUrl: 'bg/2.jpg',
    gradient: 'linear-gradient(to right top, #00bf72, #a8eb12)',
    // gradient: 'linear-gradient(to right top, #008F7A, #008E9B, #00C9A7)',
    route: 'results',
  },
  {
    id: 3,
    title: 'Free 1-to-1 Career Counselling',
    imageUrl: 'bg/3.jpg',
    // gradient: 'linear-gradient(to right top, #00bf72, #a8eb12)',
    gradient: 'linear-gradient(to right top, #051937, #004d7a, #008793)',
    // route:  'free-1-to-1-career-counselling',
    target: 'https://sarvagnainfotech.com/'
  },
  
  
];

const Directory = () => {
  return (
    <DirectoryContainer>
      {categories.map((category) => (
        <DirectoryItem key={category.id} category={category} />
      ))}
    </DirectoryContainer>
  );
};

export default Directory;
