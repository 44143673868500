import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import AuthGuard from '../../screens/signin/AuthGuard';

const Navigation = () => {
  const dispatch = useDispatch();
  const showNavigation = false;

  // get current route
  const currentRoute = window.location.pathname;
  // console.log('currentRoute', currentRoute)

  return (
    // if currentRoute is /signin or /signup then dont AuthGuard
    // else AuthGuard
    // <>
    //   {currentRoute === '/signin' || currentRoute === '/signup' ? (
    //     <Outlet />
    //   ) :
    //     (
    //       <AuthGuard>
    //         <Outlet />
    //       </AuthGuard>
    //     )}
    // </>



    // <AuthGuard>
    <>
      <Outlet />
      <h3
        style={{
          textAlign: 'center',
          opacity: 0.5,
          marginTop:'50px',
        }}
      >
        © {new Date().getFullYear()} ValueVoice.app<br />
        <div style={{
          fontSize: '0.6rem',
        }}>
        ValueVoice.app is a product of CodingWorld Developers (An AI powered software products firm).
        </div>
      </h3>
    </>

    // </AuthGuard>

  );
};

export default Navigation;
