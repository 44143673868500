import styled from 'styled-components';
import { useState } from 'react';

const Switch = ({ questionId, className, label, theme, noText, large, checked, updateSaga }) => {
  const [isChecked, setIsChecked] = useState(checked);

  const onToggle = () => {
    setIsChecked(!isChecked);
    if (updateSaga) {
      updateSaga({
        id: questionId,
        isChecked: !isChecked
      });
    }
  };

  const switchClass = `${className}${large ? ' switch--large' : ''}${
    theme === 'success' ? ' switch--success' : ''
  }${noText ? ' switch--no-text' : ''}`;

  return (
    <label className={switchClass}>
      <input
        type="checkbox"
        className="switch__input"
        aria-label={label}
        role="switch"
        checked={isChecked}
        onChange={onToggle}
      />
      <span className="switch__text" data-on="" data-off=""></span>
      <span className="switch__handle" />
    </label>
  );
};

const StyledSwitch = styled(Switch)`
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  // justify-self: flex-end;
  // align-self: center;
  height: 24px;
  // margin: 0 0 10px;
  margin: 0 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 56px;
  margin-top: ${props => props.marginTop};
  // margin-left:auto;

  &.switch--large {
    height: 34px;
    width: 74px;

    .switch__input:checked ~ .switch__handle {
      transform: translateX(40px);
    }

    .switch__text {
      height: 34px;
      width: 74px;
      font-size: 14px;
      line-height: 34px;

      &:before {
        right: 10px;
      }

      &:after {
        left: 12px;
      }
    }

    .switch__handle {
      height: 30px;
      width: 30px;
    }
  }

  &.switch--no-text {
    .switch__text {
      display: none;
    }
  }

  &.switch--success {
    .switch__input:checked ~ .switch__text {
      background-color: #5ed37a;
    }
  }

  .switch__label {
    cursor: pointer;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .switch__input {
    opacity: 0;

    &:checked ~ .switch__text:before {
      opacity: 0;
      transform: translateX(200%);
    }

    &:checked ~ .switch__text:after {
      opacity: 1;
      transform: translateX(0);
    }

    &:checked ~ .switch__handle {
      transform: translateX(32px);
    }
  }

  .switch__text {
    background-color: #d5d5d5;
    border-radius: 50px;
    box-sizing: border-box;
    display: block;
    flex: 0 0 auto;
    height: 24px;
    margin: 0;
    position: absolute;
    top: 0;
    width: 56px;
    color: SlateGrey;
    font-size: 12px;
    font-weight: 700;
    line-height: 25px;
    transition: all 0.2s ease-in-out;
    transition-property: transform;

    &:before {
      content: attr(data-off);
      right: 8px;
      transform: translateX(0);
    }

    &:after {
      color: white;
      content: attr(data-on);
      left: 9px;
      opacity: 0;
      transform: translateX(-200%);
    }
  }

  .switch__handle {
    background-color: white;
    border-radius: 18px;
    display: block;
    height: 20px;
    margin: 2px;
    padding: 0;
    position: absolute;
    top: 0;
    transition: all 0.2s ease-in-out;
    transition-property: transform;
    width: 20px;
  }
`;

export default StyledSwitch;