import { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Navigate } from 'react-router-dom';

import Button from '../../components/button/button.component';
import * as Components from './screen_6.screen.styles';
import { fetchScreen6Start } from '../../store/screen_6/screen_6.action';

import { selectScreen6, selectScreen6IsLoading } from '../../store/screen_6/screen_6.selector';
import Spinner from '../../components/spinner/spinner.component';

import AuthGuard from '../signin/AuthGuard';

const defaultFormFields = {
  arr: [],
  txtSearch: ''
};

const Screen_6_Screen = (props) => {
  const screen6 = useSelector(selectScreen6);
  const navigate = useNavigate();

  const isLoading = useSelector(selectScreen6IsLoading);

  const [formFields, setFormFields] = useState(defaultFormFields);
  const { arr, txtSearch } = formFields;
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      dispatch(fetchScreen6Start());
    }
    catch (error) {
      console.log('encountered an error', error);
    }
  }, []);




  return (
    <AuthGuard>
      <Components.ParentDiv>

        <Components.MainDiv>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
          }}>
            <img style={{
              display: 'block',
            }} src="client_logo.png" />
            <h3>
              {/* Software | Hardware | Online MBA/MCA */}
            </h3>

          </div>


          <h1>
            Psychometric Test: Career Orientation
          </h1>

          <div>
            {isLoading ? <Spinner /> : (

              <>
                <Components.FixedWidthDiv>

                  {/* [{"question":{"question":"What motivates you in your career?","choices":[{"answer":"A","text":"A high salary"},{"answer":"B","text":"Job security"},{"answer":"C","text":"Opportunities for career growth and development"},{"answer":"D","text":"A good work-life balance"}],"correct_answer":"C","hint":"","explanation":"This question assesses the candidate's values and priorities. While all answer choices are important, the correct answer is C, as it reflects a candidate's desire to learn, develop, and advance in their career."}}] */}

                  {screen6.map((item, index) => {
                    return (


                      <div key={index}>
                        <Components.Question item={item} index={index} />

                      </div>
                    )
                  })}

                </Components.FixedWidthDiv>


                <hr style={{
                  width: '100%',
                  marginTop: 20,
                  marginBottom: 20,
                }} />
                <Button

                  hoverBackgroundColor="#0556b3" hoverForegroundColor="white"
                  // on click, navigate to signup page
                  onClick={() => navigate('/results')}
                >
                  Submit And View Results
                </Button>
              </>


            )}
          </div>

        </Components.MainDiv>
      </Components.ParentDiv>

    </AuthGuard >
  );
};

export default Screen_6_Screen;
