import { useEffect, useId, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { selectUserID } from '../../store/authentication/authentication.selector';
import { useSelector } from 'react-redux';

export const ParentDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 50vh;
`;

export const SidebarDiv = styled.div`
  width: 100px;
  background-color: #f5f5f5;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

export const MainDiv = styled.div`
  flex: 1;
  padding: 20px;
`;



export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
`;

export const ColFlex1 = styled.div`
  flex: 1;
`;





const StyledInput = styled.input`
  padding: 20px;
  font-size: 1.5rem;
  width: 100%;
`;

export function InputText(props) {
  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current.focus();
  }, []);
  return <StyledInput ref={inputRef} {...props} />;
}




export const FieldHeading = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const OutputText = styled.div`
  font-size: 1.5rem;
  font-family: monospace;
  padding: 20px;
  border: 1px solid #000;
  background-color: #fffdd1;
`;


export const DivInputText = styled.div`
  font-size: 1.5rem;
  font-family: monospace;
  padding: 20px;
  border: 1px solid #000;
  background-color: #cce9ff;
`;


export const FixedWidthDiv = styled.div`
display: flex;
flex-direction: column;
// align-items: center;
width:100%;
max-width: 500px;
`;



export const FixedWidthDiv2 = styled.div`
display: flex;
flex-direction: column;
// align-items: center;
width:100%;
// max-width: 500px;
`;


export const ChoiceItemDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;

  &:hover {
    cursor: pointer;
    background-color: #0000cc;
    color: #fff;
  }
`;







export const ChoiceState = ({ choice, index, selected, onChange }) => {
  const myStyle = {
    cursor: 'pointer',
    backgroundColor: selected ? '#0000cc' : 'transparent',
    color: selected ? '#fff' : '#000',
  }

  return (
    <ChoiceItemDiv style={myStyle} onClick={() => onChange(choice.answer)}>
      <input
        type="radio"
        name={`q_${index}_choice`}
        id={`q_${index}_choice_${choice.answer}`}
        checked={selected}
        readOnly
      />
      <div style={{ fontWeight: 'bold', marginRight: '10px' }}>
        {choice.answer}.
      </div>
      <div>
        {choice.text}
      </div>
    </ChoiceItemDiv>
  );
};




export const QuestionAndSwitchContainer = styled.div`
  display: flex;
  // flex-direction: row;
  flex-direction: column;
  // space-between;
  // justify-content: space-between;
  width:100%;
  max-width: 600px;
  // flex-wrap: wrap;
  gap: 40px;
  padding:20px;
  // background-color: #f0f0f0;
  background-color: ${props => props.backgroundColor};
`;