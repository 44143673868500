import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Screen_5_Screen from '../../screens/screen_5/screen_5.screen';

const Screen_5 = () => {
  const dispatch = useDispatch();

  useEffect(() => {
  }, []);

  return (
    <Routes>
      <Route index element={<Screen_5_Screen abc='View Text' />} />
    </Routes>
  );
};

export default Screen_5;
