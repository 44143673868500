import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// import SignUp_Screen from '../../screens/signup/signup.screen';

import { SignupForm } from '../../screens/screen_8/SignupForm';

const SignUp = () => {
  const dispatch = useDispatch();

  useEffect(() => {
  }, []);

  return (
    <Routes>
      <Route index element={
        <SignupForm />
      } />
      {/* <Route path=':category' element={<Category />} /> */}
    </Routes>
  );
};

export default SignUp;
