import { SCREEN_6_ACTION_TYPES } from './screen_6.types';

import { createAction } from '../../utils/reducer/reducer.utils';

export const fetchScreen6Start = (data) =>
  createAction(SCREEN_6_ACTION_TYPES.FETCH_SCREEN_6_START, data);

export const fetchScreen6Success = (outputArray) =>
  createAction(
    SCREEN_6_ACTION_TYPES.FETCH_SCREEN_6_SUCCESS,
    outputArray
  );

export const fetchScreen6Failed = (error) =>
  createAction(SCREEN_6_ACTION_TYPES.FETCH_SCREEN_6_FAILED, error);


export const postAnswerRequest = (data) =>
  createAction(SCREEN_6_ACTION_TYPES.POST_ANSWER_REQUEST, data);