import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { selectIsAuthenticated } from '../../store/authentication/authentication.selector';

const AuthGuard = ({ children }) => {
    // const isAuthenticated = useSelector((state) => state.signin.SIGNIN_TOKEN);
    const isAuthenticated = useSelector(selectIsAuthenticated);

    
    

    if (!isAuthenticated) {
        return <Navigate to="/signup" />;
    }

    return <>{children}</>;
};

export default AuthGuard;