import { useState } from 'react';


const Screen_5_Screen = (props) => {

  return (
    <>
      Screen 5

    </>

  );
};

export default Screen_5_Screen;
