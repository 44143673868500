import { createSelector } from 'reselect';

const selectScreen6Reducer = (state) => state.screen6;

export const selectScreen6 = createSelector(
  [selectScreen6Reducer],
  (screen6Slice) => screen6Slice.screen6
);

export const selectScreen6IsLoading = createSelector(
  [selectScreen6Reducer],
  (screen6Slice) => screen6Slice.isLoading
);
