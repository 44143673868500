import { combineReducers } from 'redux';

import { screen5Reducer } from './screen_5/screen_5.reducer';
import { screen6Reducer } from './screen_6/screen_6.reducer';
import { screen8Reducer } from './screen_8/screen_8.reducer';
import { signinReducer } from './signin/signin.reducer';
import { signUpReducer } from './signup/signup.reducer';
import { authenticationReducer } from './authentication/authentication.reducer';

import { screenquestionsReducer } from './screen_questions/screen_questions.reducer';

export const rootReducer = combineReducers({
  screen5: screen5Reducer,
  screen6: screen6Reducer,
  screen8: screen8Reducer,
  signin: signinReducer,
  signUp: signUpReducer,
  authentication: authenticationReducer,
  screenquestions: screenquestionsReducer,
});
