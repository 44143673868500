import { createSelector } from 'reselect';

const selectScreenQuestionsReducer = (state) => state.screenquestions;

export const selectScreenQuestions = createSelector(
  [selectScreenQuestionsReducer],
  (screenquestionsSlice) => screenquestionsSlice.screenquestions
);

export const selectScreenQuestionsIsLoading = createSelector(
  [selectScreenQuestionsReducer],
  (screenquestionsSlice) => screenquestionsSlice.isLoading
);

export const selectScreenResultsUserAnswers = createSelector(
  [selectScreenQuestionsReducer],
  (screenquestionsSlice) => screenquestionsSlice.screenresultsuseranswers
);