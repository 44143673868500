import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Routes, Route } from 'react-router-dom';

import Home from './routes/home/home.component';
import Navigation from './routes/navigation/navigation.component';
// import Authentication from './routes/authentication/authentication.component';


import Screen_5 from './routes/screen_5/screen_5.component';
import Screen_6 from './routes/screen_6/screen_6.component';
import Screen_8 from './routes/screen_8/screen_8.component';
import Screen_Questions from './routes/screen_questions/screen_questions.component';
import Screen_Results from './routes/results/results.component';
import Signin from './routes/signin/signin.component';
import SignUp from './routes/signup/signup.component';

// import Shop from './routes/shop/shop.component';
// import Checkout from './routes/checkout/checkout.component';
// import { checkUserSession } from './store/user/user.action';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(checkUserSession());
  }, []);

  return (
    <Routes>
      <Route path='/' element={<Navigation />}>
        <Route index element={<Home />} />
        

        <Route path='screen_5/*' element={<Screen_5 />} />
        {/* <Route path='questions/*' element={<Screen_Questions />} /> */}
        {/* screen5 is just empty */}
        <Route path='test/*' element={<Screen_6 />} />
        <Route path='results/*' element={<Screen_Results />} />
        <Route path='free-1-to-1-career-counselling/*' element={<Screen_6 />} />
        {/* screen6 is quiz */}

        {/* <Route path='screen_8/*' element={<Screen_8 />} /> */}
        {/* screen8 is formik yup register form + gen01 input */}
        <Route path='signin/*' element={<Signin />} />
        <Route path='signup/*' element={<SignUp />} />
        {/* signin view (used by AuthGuard HoC Redux based controller) */}

      </Route>
    </Routes>
  );
};

export default App;
