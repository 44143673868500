import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Screen_6_Screen from '../../screens/screen_6/screen_6.screen';

const Screen_6 = () => {
  const dispatch = useDispatch();

  useEffect(() => {
  }, []);

  return (
    <Routes>
      <Route index element={<Screen_6_Screen abc='View Text' />} />
    </Routes>
  );
};

export default Screen_6;
