import { takeEvery, takeLatest, all, call, put } from 'redux-saga/effects';
import { API_URL } from '../config';
import { SCREEN_QUESTIONS_ACTION_TYPES } from './screen_questions.types';
import { supabase } from '../config';
import { select } from 'redux-saga/effects';

import { selectUserID } from '../authentication/authentication.selector';


import {
  fetchScreenQuestionsSuccess,
  fetchScreenQuestionsFailed,
  fetchScreenResultsUserAnswersSuccess,
  fetchScreenResultsUserAnswersFailed,
} from './screen_questions.action';

// import axios
import axios from 'axios';

export function* fetchScreenQuestionsAsync() {

  try {

    // const response = yield call(axios.get, `${API_URL}/screen_questions`);

    let { data, error } = yield call(async (supabase) => {
      const ans = await supabase
        .from('intg_test_questions')
        .select('*')
        .order('status', { ascending: false })
        .order('id', { ascending: false })

      // console.log("ans", ans)
      return ans;

    }, supabase);



    yield put(fetchScreenQuestionsSuccess(data));
  } catch (error) {
    yield put(fetchScreenQuestionsFailed(error));
  }
}

export function* onfetchScreenQuestions() {
  yield takeEvery(
    SCREEN_QUESTIONS_ACTION_TYPES.FETCH_SCREEN_QUESTIONS_START,
    fetchScreenQuestionsAsync
  );
}





export function* fetchScreenResultsUserAnswersAsync() {

  try {

    // const response = yield call(axios.get, `${API_URL}/screen_questions`);
    const my_uid = yield select(selectUserID);


    let { data, error } = yield call(async (supabase, my_uid) => {
      const ans = await supabase
        .from('intg_user_answers')
        .select('*')
        .eq('user_id', my_uid)

      // console.log("ans", ans)
      return ans;

    }, supabase, my_uid);



    yield put(fetchScreenResultsUserAnswersSuccess(data));
  } catch (error) {
    yield put(fetchScreenResultsUserAnswersFailed(error));
  }
}

export function* onfetchScreenResultsUserAnswers() {
  yield takeEvery(
    SCREEN_QUESTIONS_ACTION_TYPES.FETCH_SCREEN_RESULTS_USER_ANSWERS_START,
    fetchScreenResultsUserAnswersAsync
  );
}





export function* postQuestionStatusRequestAsync(action) {

  try {

    // const response = yield call(axios.get, `${API_URL}/screen_questions`);
    const yieldCallPayload = {
      question_id: action.payload.question_id,
      status: action.payload.status,
    };


    let { data, error } = yield call(async (supabase, yieldCallPayload) => {
      console.log("yieldCallPayload", yieldCallPayload)

      const { data, error } = await supabase
        .from('intg_test_questions')
        .update({ status: yieldCallPayload.status })
        .eq('id', yieldCallPayload.question_id)
        .select()
        .then((data, error) => {
          console.log("data", data)
        })

      // console.log("error", error)

      return data;
    }, supabase, yieldCallPayload);

    // console.log("error", error)



    // yield put(fetchScreenQuestionsAsync());
  } catch (error) {
    // console.log("error", error)
    // yield put(fetchScreenQuestionsFailed(error));
  }
}

export function* onpostQuestionStatusRequest() {
  yield takeEvery(
    SCREEN_QUESTIONS_ACTION_TYPES.POST_QUESTION_STATUS_REQUEST,
    postQuestionStatusRequestAsync
  );
}

export function* screenquestionsSaga() {
  yield all([
    call(onfetchScreenQuestions),
    call(onpostQuestionStatusRequest),
    call(onfetchScreenResultsUserAnswers),
  ]);
}