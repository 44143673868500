import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactDOM from "react-dom";
import { Formik, Form, } from "formik";
import * as Yup from "yup";
import Button from "../../components/button/button.component";
import { createClient } from "@supabase/supabase-js";

import { user_organization_name, user_organization_app_heading } from "../../store/config";

import MyContainer from "./MyContainer";
import * as Components from './signin.screen.styles';

import { ajaxSigninSubmitStart } from '../../store/signin/signin.action';
import { useNavigate, Navigate } from 'react-router-dom';
import { selectIsAuthenticated } from '../../store/authentication/authentication.selector';

import { MyTextInput, Container } from './LoginForm2.styles';


export const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isAuthenticated = useSelector(selectIsAuthenticated);

  // const MyContainer = (<Container>

  //   <Formik
  //     initialValues={{
  //       email: "",
  //       password: "",
  //     }}
  //     validationSchema={Yup.object({
  //       email: Yup.string()
  //         .email("Invalid email addresss`")
  //         .required("Required"),
  //       password: Yup.string()
  //         .min(8, "Must be 8 characters or more")
  //         .required("Required"),
  //     })}
  //     onSubmit={async (formDataInJson, { setSubmitting }) => {

  //       dispatch(ajaxSigninSubmitStart({
  //         ...formDataInJson,
  //         navigate
  //       }));

  //       setSubmitting(true);

  //     }}
  //   >
  //     {({ isSubmitting }) => (
  //       <Form>
  //         <div style={{
  //           display: 'flex',
  //           flexDirection: 'column',
  //           gap: 20,
  //           // padding: 20,
  //           maxWidth: 400,
  //           flex: 1,
  //         }}>
  //           <h1>
  //           {user_organization_name}: {user_organization_app_heading}
  //           </h1>

  //           <Components.HeaderDiv>
  //             <Components.HeaderTitle>Sign In</Components.HeaderTitle>
  //           </Components.HeaderDiv>

  //           <MyTextInput
  //             label="Email Address"
  //             name="email"
  //             type="email"
  //             placeholder="Eg: uday.email@example.com"
  //             tabIndex="1"
  //           />




  //           {/* password */}
  //           <MyTextInput
  //             label="Password"
  //             name="password"
  //             type="password"
  //             placeholder="********"
  //           />



  //           <Button
  //             hoverBackgroundColor="#34b800" hoverForegroundColor="white"
  //             type="submit"
  //             disabled={isSubmitting}
  //           >Submit</Button>


  //           <hr style={{
  //             width: '100%',
  //             marginTop: 20,
  //             marginBottom: 20,
  //           }} />
  //           <Button

  //             hoverBackgroundColor="#34b800" hoverForegroundColor="white"
  //             // on click, navigate to signup page
  //             onClick={() => navigate('/signup')}
  //           >
  //             Sign Up (New Users)
  //           </Button>


  //           {/* </div> */}
  //         </div>
  //       </Form>
  //     )}

  //   </Formik>




  // </Container>);


  return (
    <>
      
      {isAuthenticated ? (
        <Navigate to="/" />
      ) : (
        <>
          <MyContainer
            user_organization_name={user_organization_name}
            user_organization_app_heading={user_organization_app_heading}
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email("Invalid email addresss")
                .required("Required"),
              password: Yup.string()
                .min(8, "Must be 8 characters or more")
                .required("Required"),
            })}
            myOnSubmit={async (formDataInJson, { setSubmitting }) => {
              dispatch(
                ajaxSigninSubmitStart({
                  ...formDataInJson,
                  navigate,
                })
              );

              setSubmitting(true);
            }}
            navigate={navigate}
          />
        </>
      )}
    </>
  );
};