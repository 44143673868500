import React from "react";
import { Formik, Form } from "formik";

import * as Components from './signin.screen.styles';
import Button from "../../components/button/button.component";
import { MyTextInput, Container } from './LoginForm2.styles';



const MyContainer = ({ user_organization_name,
    user_organization_app_heading,
    initialValues, validationSchema,
    myOnSubmit, navigate }) => {


    return (
        <Container>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={myOnSubmit}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 20,
                                maxWidth: 400,
                                flex: 1,
                            }}
                        >
                            <h1>
                                {/* {user_organization_name}:  */}
                                {user_organization_app_heading}
                            </h1>
                            <Components.HeaderDiv>
                                <Components.HeaderTitle>Sign In</Components.HeaderTitle>
                            </Components.HeaderDiv>
                            <MyTextInput
                                label="Email Address"
                                name="email"
                                type="email"
                                placeholder="Eg: uday.email@example.com"
                                tabIndex="1"
                            />
                            <MyTextInput
                                label="Password"
                                name="password"
                                type="password"
                                placeholder="********"
                            />
                            <Button
                                hoverBackgroundColor="#34b800"
                                hoverForegroundColor="white"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                Submit
                            </Button>
                            <hr
                                style={{
                                    width: "100%",
                                    marginTop: 20,
                                    marginBottom: 20,
                                }}
                            />
                            <Button
                                hoverBackgroundColor="#34b800"
                                hoverForegroundColor="white"
                                onClick={() => navigate("/signup")}
                            >
                                Sign Up (New Users)
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Container>
    );
}

export default MyContainer;