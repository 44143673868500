import styled from 'styled-components';

export const DirectoryContainer = styled.div`
  width: 100%;
  display: flex;
  gap:20px;
  // flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  
`;
